import { LitElement, html } from 'lit-element';
import tiltElementOnMouseover from '/components/effects/tilt.js';

export default class ExhibitionSingle extends LitElement {
  static get properties() {
    return {
      url: { type: String },
      title: { type: String },
      image: { type: String },
      image_sm: { type: String },
      image_lg: { type: String },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.url = './exhibitions';
    this.title = 'title';
  }

  firstUpdated() {
    tiltElementOnMouseover(this, 0.005, 0.005);

    this.querySelector('.exhibitions-item-wrapper').addEventListener('click', ev => {
      ev.preventDefault();
      location.assign(ev.currentTarget.href);
    });
  }

  render() {
    return html`
    <div class="exhibitions-item">
      <a href="${this.url}" class="exhibitions-item-wrapper">
        <picture class="exhibitions-item-image">
          <source media="(max-width: 700px)" srcset="${this.image_sm}">
          <source media="(min-width: 701px)" srcset="${this.image_lg}">
          <img src="${this.image}" alt="Exhibition thumbnail">
        </picture>
        <p class="exhibitions-item-title">
          <span data-glitch="${this.title}">${this.title}</span>
        </p>
      </a>
    </div>
    `;
  }
}
