import { LitElement, html } from 'lit-element';
import tiltElementOnMouseover from '/components/effects/tilt.js';
import gsap from '/components/effects/gsapScrollTrigger.js';

export default class FeaturedArtist extends LitElement {
  static get properties() {
    return {
      url: { type: String, },
      name: { type: String, },
      excerpt: { type: String, },
      mugshot: { type: String, },
      background: { type: Array, },
    };
  }

  constructor() {
    super();
    this.url = './';
    this.name = 'John Doe';
    this.excerpt = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';
    this.mugshot = './images/its_a_logo.png';
    this.background = [];
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    // tiltElementOnMouseover(this);
    if ( this.background.length ) {
      this.background.forEach((bg, i) => {
        let image = document.createElement('img');
        image.classList.add('home-featured-artist-background-image');
        image.src = bg;

        let scatterX = Math.random() * 50 - 25;
        let scatterY = Math.random() * 120 - 60;
        let scatterZ = Math.random() * -500 - 200;
        image.style.left = `${scatterX}%`;
        image.style.top = `${scatterY}%`;
        image.style.transform = `translate3d(0, 0, ${scatterZ}px)`;
        image.style.filter = `blur(${-scatterZ / 20}px)`;
        gsap.to(image, {
          scrollTrigger: {
            trigger: '#home-featured-content',
            start: 'top center',
            scrub: 0.5,
          },
          y: '+18%',
        });

        this.querySelector('.home-featured-artist-background').appendChild(image);
      });
    }
  }

  render() {
    return html`
      <div class="home-featured-artist">
        <div class="home-featured-artist-wrapper">
          <div class="home-featured-artist-content">
            <div class="home-featured-artist-details">
              <h5 class="home-featured-artist-name">
                <span>${this.name}</span>
              </h5>
              <p class="home-featured-artist-description">
                ${this.excerpt}
              </p>
              <a href="${this.url}" class="button button-fill home-featured-artist-button">
                <span data-glitch="Read more about ${this.name.split(' ').shift()}">Read more about ${this.name.split(' ').shift()}</span>
              </a>
            </div>
            <figure class="home-featured-artist-image">
              <img src="${this.mugshot}" alt="image of artist">
            </figure>
          </div>
          <div class="home-featured-artist-background">
            <!-- Populated with JS -->
          </div>
        </div>
      </div>
    `;
  }
}
