import { LitElement, html } from 'lit-element';
import gsap from '/components/effects/gsapScrollTrigger.js';
import isObjectEmpty from '/components/effects/isObjectEmpty.js';
import '/components/etc/im-video.js';

export default class Home extends LitElement {
  static get properties() {
    return {
      artists: { type: Array, },
      residency: { type: Array, },
      exhibitions: { type: Array, },
      catalogueList: { type: Array, },
      exhibitionsList: { type: Array, },
      featuredList: { type: Array, },
      upcoming_resident: { type: Object, },
      digital_installations: { type: Array, },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.artists = [];
    this.residency = [];
    this.exhibitions = [];

    this.catalogueList = [];
    this.exhibitionsList = [];
    this.featuredList = [];
    this.upcoming_resident = {};
    this.digital_installations = [];
  }

  firstUpdated() {
    this.catalogueList = this.artists.slice(0, 8);
    this.exhibitionsList = this.exhibitions.slice(0, 3);
    this.featuredList = this.artists.filter(artist => artist?.featured);
  }

  render() {
    return html`
      <section id="home-hero">
        <div id="home-hero-container">
          <div id="home-hero-wrapper">
            <!-- <video src="/videos/landing-intro-lg.mp4" autoplay muted playsinline loop></video> -->
            <im-video autoplay muted playsinline loop
              src="/videos/landing-intro-lg.mp4"
              srcset="/videos/landing-intro-sm.mp4 500px"
            ></im-video>
          </div>
        </div>
      </section>

      <section id="home-catalogue">
        <div id="home-catalogue-container">
          <div id="home-catalogue-wrapper">
            <aom-catalogue-container .artists="${this.catalogueList}"></aom-catalogue-container>
          </div>
        </div>
      </section>

      <section id="home-featured">
        <div id="home-featured-container">
          <div id="home-featured-wrapper">
            <aom-section-heading id="home-featured-heading" .text="${"Featured\nArtist"}"></aom-section-heading>
            <aom-featured-container id="home-featured-content" .artists=${this.featuredList}></aom-featured-container>
            <div id="home-featured-artists-link">
              <a href="./catalogue" class="button button-fill">
                <span data-glitch="Discover more artists">Discover more artists</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="home-residency">
        <div id="home-residency-container">
          <div id="home-residency-wrapper">
            <aom-section-heading id="home-residency-heading" text="Residency"></aom-section-heading>

            ${ isObjectEmpty(this.upcoming_resident) ? null :
              html`
                <aom-residency-upcoming
                  name="${this.upcoming_resident.name}"
                  url="${this.upcoming_resident.url}"
                  image="${this.upcoming_resident.image}"
                ></aom-residency-upcoming>
              `
            }

            ${ !this.digital_installations.length ? null :
              html`
                <div id="home-residency-installation">
                  <h3 id="home-residency-installation-heading">
                    <span>digital<br> installations</span>
                  </h3>
                  <aom-residency-installation-container .installations="${this.digital_installations}"></aom-residency-installation-container>
                </div>
              `
            }

            <div id="home-residency-link">
              <a href="./residency" class="button button-fill">
                <span data-glitch="Our residency program">Our residency program</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="home-exhibitions">
        <div id="home-exhibitions-container">
          <div id="home-exhibitions-wrapper">
            <aom-section-heading id="home-exhibitions-heading" text="Current Exhibitions"></aom-section-heading>

            <aom-exhibition-container .exhibitions="${this.exhibitionsList}"></aom-exhibition-container>

          </div>
        </div>
      </section>
    `;
  }
}
