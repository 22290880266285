import { LitElement, html } from 'lit-element';
import gsap from '/components/effects/gsapScrollTrigger.js';

export default class Topbar extends LitElement {
  static get properties() {
    return {
      routes: { type: Array, hasChanged(oldValue, newValue) {
        if ( oldValue ) {
          return oldValue.length;
        }
      } },
    }
  }
  
  constructor() {
    super();
    this.routes = [];
    this.nav = ['about', 'catalogue', 'residency', 'exhibitions'];
  }

  createRenderRoot() {
    return this;
  }

  bindToggle() {
    const topbarSlideAnimation = gsap.to('#nav', { y: 0, duration: 0.55, ease: 'ease', reversed: true});
    topbarSlideAnimation.pause();

    function toggleNav() {
      topbarSlideAnimation.reversed() ? topbarSlideAnimation.play() : topbarSlideAnimation.reverse();
    }

    this.querySelector('#topbar-sm-burger_button').addEventListener('click', ev => {
      toggleNav();
    });

    this.querySelectorAll('a').forEach((a, i) => {
      if ( i > 0 ) {
        a.addEventListener('click', () => toggleNav());
      }
    });
  }

  updated(prop) {
    if ( prop.has('routes') ) {
      this.bindToggle();
    }
  }

  render() {
    return html`
    <header id="topbar">
      <div id="topbar-container">
        <div id="topbar-sm">
          <div id="topbar-sm-logo">
            <a href="/" aria-label="home">
              <img src="/images/logo-small.png" alt="Anthology of Metaverses Logo">
            </a>
          </div>
          <button id="topbar-sm-burger_button">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
          </button>
        </div>
        <nav id="nav">
          <div id="nav-logo-lg">
            <a href="/" aria-label="home">
              <img src="/images/logo-small.png" alt="Anthology of Metaverses Logo">
            </a>
          </div>
          <div id="nav-main">
            ${ this.routes && (this.routes.filter(route => this.nav.includes(route.id))
              .map(route => html`
                <a href="${route.path}" class="nav-main-link ${location.pathname == route.path ? 'active' : '' }">
                  <span class="nav-main-link-text" data-glitch="${route.id}">${route.id}</span>
                </a>`)
            )}
          </div>
          <div id="topbar-buttons-container">
            <a href="/residency" id="topbar-submit-button" class="topbar-button button button-border">
              <span data-glitch="Exhibit your artwork">Exhibit your artwork</span>
            </a>
            <a href="https://www.instagram.com/anthologyofmetaverses/" aria-label="instagram link" id="topbar-ig-link" class="topbar-button">
              <img src="/images/instagram.svg" alt="instagram link icon">
            </a>
          </div>
        </nav>
      </div>
    </header>
    `;
  }
}
