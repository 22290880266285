import { LitElement, html } from 'lit-element';

export default class ResidencyInstallationContainer extends LitElement {
  static get properties() {
    return {
      installations: { type: Array, },
    };
  }

  constructor() {
    super();
    this.installations = [];
  }

  createRenderRoot() {
    return this;
  }

  updated() {
    if ( this.installations.length ) {
      this.installations.forEach((installation, i) => {
        let node = document.createElement('aom-residency-installation');
        node.url = installation.url;
        node.caption = installation.caption;
        node.image = installation.image;
        node.name = installation.name;
        this.querySelector('#home-residency-installation-content').appendChild(node);
      });
    }
  }

  render() {
    return html`
      <div id="home-residency-installation-content">
        <!-- Populated by JS -->
      </div>
    `;
  }
}
