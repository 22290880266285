/**
 *  Checks if Object is empty
 *  @params {Object} obj, Object to check.
 */

export default function isObjectEmpty(obj) {
  if ( ( Object.keys(obj).length === 0 && obj.constructor === Object ) ) {
    return true;
  }
  return false;
}
