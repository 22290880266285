import { LitElement, html } from 'lit-element';

export default class Exhibition extends LitElement {
  static get properties() {
    return {
      exhibitions: { type: Array },
    };
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {

  }

  render() {
    return html`
      <section id="exhibitions-hero">
        <div id="exhibitions-hero-container">
          <div id="exhibitions-hero-wrapper">
            <aom-section-heading .text=${'Exhibitions'}></aom-section-heading>
            <div id="exhibitions-hero-paragraph-wrapper" class="exhibitions-centre">
              <p class="section-paragraph">
                Being distinct from art made through traditional and established methods, formats and materials, digital art and internet art often sits just outside in a world unto itself. To its advantage, which allowed a unique set of digital practices to flourish. However, surprisingly digital art practices still sits on the periphery of the contemporary art ecosystem, despite the new ideas and techniques that it offers. We feel that it is timely to expose the practices of multidisciplinary and cross-discipline artists, designers, and collectives, who explore the digital as medium.
              </p>
            </div>
            <aom-exhibition-container .exhibitions=${this.exhibitions}></aom-exhibition-container>
          </div>
        </div>
      </section>
    `;
  }
}
