import { LitElement, html } from 'lit-element';
import DOMPurify from 'dompurify';
import marked from 'marked/lib/marked.esm.js';

export default class Home extends LitElement {

  static get properties() {
    return {
      data: { type: Object, },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();

    this.data = {};
    this.description = [];
    this.team = [];
  }

  firstUpdated() {
    let descriptionHTML = DOMPurify.sanitize(marked(this.data.description));
    let descriptionParsed = new DOMParser().parseFromString(descriptionHTML, 'text/html');
    descriptionParsed.querySelectorAll('p').forEach((p) => {
      p.classList.add('about-paragraph', 'section-paragraph');
    })
    this.description = [...descriptionParsed.body.children];

    this.team = this.data.team;
    this.requestUpdate();
  }

  render() {
    return html`
    <main>
      <section id="about-hero">
        <div id="about-hero-container" class="about-container">
          <div id="about-hero-wrapper" class="about-wrapper">
            <aom-section-heading .text="${'about AOM'}" left></aom-section-heading>
            ${ this.description.map(el => html`${el}`) }
          </div>
        </div>
      </section>

      <section id="about-team">
        <div id="about-team-container" class="about-container">
          <div id="about-team-wrapper" class="about-wrapper">
            <aom-section-heading .text="${'the team'}" left></aom-section-heading>
            <dl id="about-team-content">
              ${ this.team.map(mate => html`
                <div class="about-team-item">
                  <dt>${mate.role}</dt>
                  <dd>${mate.name}</dd>
                </div>
              `) }
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section id="about-contact">
        <div id="about-contact-container" class="about-container">
          <div id="about-contact-wrapper" class="about-wrapper">
            <aom-section-heading .text="${'contact'}" left></aom-section-heading>
            <p id="about-contact-paragraph" class="about-paragraph section-paragraph">For research or art related collaborations, or for any questions you may have, you can reach us here: <a href="maito:anthologyofmetaverses@gmail.com"><span data-glitch="anthologyofmetaverses@gmail.com">anthologyofmetaverses@gmail.com</span></a></p>
          </div>
        </div>
      </section>
    </main>
    `;
  }
}
