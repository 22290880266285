import { LitElement, html } from 'lit-element';
import gsap from '/components/effects/gsapScrollTrigger.js';
import mdToHTML from '../effects/mdToHTML.js';

export default class Residency extends LitElement {
  static get properties() {
    return {
      residents: { type: Array, },
      introduction: { type: String, },
      themes: { type: String, },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.introduction = '';
    this.themes = '';
  }

  firstUpdated() {
    // NOTE: Hydrate content
    fetch('/data/sections/residency.json')
      .then(res => res.json())
      .then(json => {
        this.introduction = json.introduction;
        this.themes = json.themes;
      });

    // NOTE: Animations
    gsap.to(this.querySelector('#residency-theme-value'), {
      scrollTrigger: {
        trigger: '#residency-theme',
        toggleActions: 'play pause resume reset',
        scrub: 0.5,
      },
      scale: 1.15,
    });
  }

  render() {
    return html`
      <section id="residency-hero">
        <div>
          <div id="residency-hero-wrapper">
            <aom-section-heading id="residency-hero-heading" .text="${'online\nresidency'}"></aom-section-heading>
          </div>
        </div>
      </section>

      <section id="residency-intro">
        <div id="residency-intro-container">
          <aom-section-heading .text="${'Introduction'}"></aom-section-heading>
          <div 
            id="residency-intro-wrapper"
            class="residency-centre md-body"
            .innerHTML=${ this.introduction.length ? mdToHTML(this.introduction) : 'Loading...' }
          ></div>
        </div>
      </section>

      <section id="residency-theme">
        <div id="residency-theme-container">
          <aom-section-heading .text="${'Themes'}"></aom-section-heading>
          <div
            id="residency-theme-wrapper"
            class="residency-centre md-body"
            .innerHTML=${ this.themes.length ? mdToHTML(this.themes) : 'Loading...' }
          >
          </div>
        </div>
      </section>

      <section>
        <aom-section-heading id="residency-hero-heading" .text="${'The Residents'}"></aom-section-heading>
      </section>
      <section id="residents-listing">
        <aom-exhibition-container .exhibitions=${this.residents}></aom-exhibition-container>
      </section>

    `;
  }
}
