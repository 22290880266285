import { LitElement, html } from 'lit-element';
import tiltElementOnMouseover from '/components/effects/tilt.js';

export default class CatalogueArtist extends LitElement {
  static get properties() {
    return {
      url: { type: String, },
      name: { type: String, },
      designation: { type: String, },
      mugshot: { type: String, },
      altText: { type: String, },
    };
  }

  constructor() {
    super();
    this.url = './';
    this.name = 'Name';
    this.designation = 'Designation';
    this.mugshot = './images/its_a_logo.png';
    this.altText = 'artist mugshort or image placeholder';
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    tiltElementOnMouseover(this);
  }

  render() {
    return html`
      <div class="catalogue-artist">
        <a href="${this.url}" class="catalogue-artist-wrapper">
          <figure class="catalogue-artist-image">
            <img src="${this.mugshot}" alt="${this.altText}">
          </figure>
          <p class="catalogue-artist-name">
            <span data-glitch="${this.name}">${this.name}</span>
          </p>
          <p class="catalogue-artist-designation">
            <span data-glitch="${this.designation}">${this.designation}</span>
          </p>
        </a>
      </div>
    `;
  }
}
