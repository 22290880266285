/**
 *  Tilts (pitch and roll) effects on said element.
 *  @param {DOMElement} element, the DOM element which the effect acts on.
 *  @param {Float} xRotateStrength, rotation multiplier.
 *  @param {Float} yRotateStrength, rotation multiplier.
 */

export default function tiltElementOnMouseover(element, xRotateStrength = 0.03, yRotateStrength = 0.03) {
  let entered = false;

  element.addEventListener('mouseenter', ev => {
    entered = true;
  });

  element.addEventListener('mouseleave', ev => {
    entered = false;
    element.animate(
      { transform: `perspective(500px) rotateX(0deg) rotateY(0deg)`, },
      { duration: 500, fill: 'forwards', },
    )
  });

  element.addEventListener('mousemove', ev => {
    if ( entered ) {
      let coordX = ev.offsetX - ev.target.clientWidth / 2;
      let coordY = ev.offsetY - ev.target.clientHeight / 2;
      let rotateX = coordX * xRotateStrength;
      let rotateY = coordY * yRotateStrength;
      element.animate(
        { transform: `perspective(500px) scale(1.02) rotateX(${-rotateY}deg) rotateY(${rotateX}deg)`, },
        { duration: 500, fill: 'forwards', },
      )
    }
  });

}
