import { LitElement, html, css } from 'lit-element';
import gsap from '/components/effects/gsapScrollTrigger.js';

export default class Topbar extends LitElement {
  static get styles() {
    return [
      css`
        .section-heading {
          color: inherit;
        }
      `,
    ];
  }

  static get properties() {
    return {
      text: { type: String, },
      left: { type: Boolean, reflect: true, },
    };
  }

  constructor() {
    super();
    this.text = 'heading';
    this.left = false;
    this.scrollAnimation;
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    /**
     *  animation.
     */

    this.scrollAnimation = gsap.from(this.querySelector('span'), {
      scrollTrigger: {
        trigger: this,
        toggleActions: 'play pause resume reset',
        scrub: 0.5,
      },
      scale: 0.85,
    });

    setTimeout(() => {
      this.scrollAnimation.scrollTrigger.refresh(true);
    }, 1);
  }

  render() {
    return html`
    <h2 class="section-heading${this.left ? ' section-heading-left' : ''}">
      <span>${this.text}</span>
    </h2>
    `;
  }
}
