import { LitElement, html } from 'lit-element';

export default class Footer extends LitElement {
  static get properties() {
    return {
      routes: { type: Array, },
    };
  }

  constructor() {
    super();
    this.routes = [];
    this.nav = ['about', 'catalogue', 'residency', 'exhibitions'];
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
    <footer id="footer">
      <div id="footer-container">
        <div id="footer-wrapper">
          <nav id="footer-nav">
            <div id="footer-nav-content">
              ${ this.routes && this.routes.filter(route => this.nav.includes(route.id))
                .map(route => html`
                  <a href="${route.path}" class="footer-nav-link">
                    <span class="footer-nav-link-text" data-glitch="${route.id}">${route.id}</span>
                  </a>`)
              }
            </div>
          </nav>
          <div id="footer-meta">
            <p id="footer-meta-copyright">
              <span>© Anthology of Metaverses <span>2020</span>. All Rights Reserved</span>
            </p>
            <nav id="footer-meta-legal">
              <a href="/terms" class="footer-meta-legal-link">
                <span>Terms and Condition</span>
              </a>
              <a href="/policy" class="footer-meta-legal-link">
                <span>Privacy Policy</span>
              </a>
            </nav>
            <p id="footer-meta-sponsors">
              <span>Supported by <a href="https://www.cendana.com.my/"><strong>CENDANA</strong></a></span>
            </p>
          </div>
        </div>
      </div>
    </footer>
    `;
  }
}
