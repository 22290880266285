import { LitElement, html } from 'lit-element';
import tiltElementOnMouseover from '/components/effects/tilt.js';
import CatalogueURL from '/components/catalogue/CatalogueURL.js';
import mdToHTML from '../effects/mdToHTML.js';

export default class CatalogueSingle extends LitElement {
  static get properties() {
    return {
      artistURL: { type: String, },
      artists: { type: Array, },
      artist: { type: Object, },
    };
  }

  constructor() {
    super();
    this.artist = {};
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    this.artist = CatalogueURL.getArtist(this.artistURL, this.artists);
    this.querySelector('.md-body').innerHTML = mdToHTML(this.artist.body);
  }

  render() {
    return html`
      <section id="catalogue-single-hero">
        <div id="catalogue-single-hero-container">
          <div id="catalogue-single-hero-image">
            <img src="${this.artist.background}" alt="Artist top hero background" />
          </div>
          <div id="catalogue-single-hero-text">
            <p id="catalogue-single-hero-name">${this.artist.name}</p>
            <p id="catalogue-single-hero-designation">${this.artist.designation}</p>
          </div>
        </div>
      </section>
      <div id="catalogue-single-body">
        <div id="catalogue-single-body-container">
          <div id="catalogue-single-body-wrapper" class="md-body">
            <!-- Populated by JS -->
          </div>
        </div>
      </div>

    `;
  }
}
