import { LitElement, html } from 'lit-element';
import tiltElementOnMouseover from '/components/effects/tilt.js';
import gsap from '/components/effects/gsapScrollTrigger.js';

export default class ResidencyInstallation extends LitElement {
  static get properties() {
    return {
      url: { type: String, },
      name: { type: String, },
      image: { type: String, },
      caption: { type: String, },
      text: { type: Array, },
    };
  }

  constructor() {
    super();
    this.url = './';
    this.image = './images/its_a_logo.png';
    this.caption = 'Its a Website';
    this.name = 'name';
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    tiltElementOnMouseover(this);
  }

  render() {
    return html`
      <a target="_blank" href="${this.url}" class="home-residency-installation-item">
        <figure class="home-residency-installation-image">
          <img src="${this.image}" alt="resident's website">
          <figcaption>${this.caption}</figcaption>
        </figure>
        <p class="home-residency-installation-text">
          <span data-glitch="${this.name}">${this.name}</span>
        </p>
      </a>
    `;
  }
}
