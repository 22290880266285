import { LitElement, html } from 'lit-element';

export default class CataloguePageContainer extends LitElement {
  static get properties() {
    return {
      year: { type: String },
      artists: { type: Array },
    };
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <section class="catalogue-section">
        <div class="catalogue-section-container">
          <div class="catalogue-section-wrapper">
            <aom-section-heading .text="${this.year}" left></aom-section-heading>
            <aom-catalogue-container .artists=${this.artists}></aom-catalogue-container>
          </div>
        </div>
      </section>
    `;
  }
}
