import { LitElement, html } from 'lit-element';
import { installRouter } from 'pwa-helpers/router.js';

export default class Main extends LitElement {
  static get properties() {
    return {
      artists: { type: Array },
      exhibitions: { type: Array },
      residents: { type: Array },
    };
  }

  createRenderRoot() {
    return this;
  }

  async firstUpdated() {

    /**
    *  Fetches data and filters in datum with display: true
    */

    async function fetchAndFilter(url) {
      let data = await fetch(url).then(resp => resp.json()).catch(console.error);
      return data.filter(datum => datum.display);
    }

    let upcoming_resident = await fetch('/data/sections/upcoming_resident.json').then(resp => resp.json()).catch(console.error);
    upcoming_resident = upcoming_resident?.display ? upcoming_resident : {};

    let digital_installations = [
      await fetch('/data/sections/digital_installation_1.json').then(resp => resp.json()).catch(console.error),
      await fetch('/data/sections/digital_installation_2.json').then(resp => resp.json()).catch(console.error)
    ];
    digital_installations = digital_installations.filter(datum => datum.display);

    const aboutData = await fetch('/data/sections/about.json').then(resp => resp.json()).catch(console.error);
    this.artists = await fetchAndFilter('/data/artists.json');
    this.exhibitions = await fetchAndFilter('/data/exhibitions.json');
    this.residents = await fetchAndFilter('/data/residents.json');

    this.routes = [
      { id: 'home', path: '/', component: 'aom-home',
        props: {
          artists: this.artists,
          exhibitions: this.exhibitions,
          upcoming_resident,
          digital_installations,
        }
      },
      { id: 'catalogue', path: '/catalogue',
        children: [
          { path: '', component: 'aom-catalogue', props: { artists: this.artists, }, },
          { path: '/:artistURL', component: 'aom-catalogue-single', props: { artists: this.artists, }, },
        ],
      },
      { id: 'residency', path: '/residency', component: 'aom-residency', props: { residents: this.residents, } },
      { id: 'exhibitions', path: '/exhibitions', component: 'aom-exhibition', props: { exhibitions: this.exhibitions, } },
      { id: 'about', path: '/about', component: 'aom-about', props: { data: aboutData }},
      { id: 'admin', path: '/admin', redirect: './admin/index.html', },
    ];

    async function handleNavigation(location, routes, outlet) {
      let pathname = location.pathname;
      let route = routes.find(route => route.path.split('/')[1] === pathname.split('/')[1]);
      let component;

      if ( route ) {
        component = document.createElement(route.component);
        if ( route.redirect ) {
          location.assign(route.redirect);
          return;
        }

        if ( route.children ) {
          let childRoute = pathname.split('/')[2];
          if ( childRoute ) {
            route = route.children.find(route => route.path !== '');
            let propKey = route.path.split('/:').pop();
            let propValue = childRoute;
            component = document.createElement(route.component);
            component[propKey] = propValue;
          } else {
            route = route.children.find(route => route.path === '');
            component = document.createElement(route.component);
          }
        }
        if ( route.props ) {
          for ( let [key, value] of Object.entries(route.props) ) {
            component[key] = value;
          }
        }
      } else {
        // NOTE: fallback to home page.
        component = document.createElement('aom-home');
      }

      while ( outlet.lastElementChild ) outlet.lastElementChild.remove();
      outlet.appendChild(component);
      window.scrollTo(0, 0);
    }

    const outlet = this.querySelector('main');
    installRouter(async (location) => {
      await handleNavigation(location, this.routes, outlet);
      this.querySelector('aom-topbar').requestUpdate();
    });

  }

  render() {
    return html`
      <aom-topbar .routes=${this.routes}></aom-topbar>
      <main></main>
      <aom-footer .routes=${this.routes}></aom-footer>
    `;
  }
}
