import { LitElement, html, css } from 'lit-element';
import gsap from '/components/effects/gsapScrollTrigger.js';

export default class ResidencyUpcoming extends LitElement {
  static get properties() {
    return {
      url: { type: String, },
      name: { type: String, },
      image: { type: String, },
    };
  }

  constructor() {
    super();
    this.url = './';
    this.image = './images/its_a_logo.png';
    this.name = 'John Doe';
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {

    /**
     *  Initiate .home-residency-main-tape animations on scroll.
     */

    let tapes = this.querySelectorAll('.home-residency-main-tape');

    new Promise(resolve => {
      tapes.forEach((tape, i) => {
        let count = 10;
        let repeatText = !tape.dataset.repeat ? 'Upcoming Resident' : tape.dataset.repeat;
        tape.textContent = (tape.dataset.repeat + ' ').repeat(count);
      });
      setTimeout(() => resolve(), 1);
    }).then(() => {
      gsap.to('.home-residency-main-tape:nth-child(1)', {
        scrollTrigger: {
          trigger: this.querySelector('#home-residency-main'),
          triggerActions: 'start resume none none',
          scrub: 0.5,
        },
        x: '-=20%',
      });

      gsap.to('.home-residency-main-tape:nth-child(2)', {
        scrollTrigger: {
          trigger: this.querySelector('#home-residency-main'),
          triggerActions: 'start resume none none',
          scrub: 0.5,
        },
        x: '+=20%',
      });
    });

  }

  render() {
    return html`
    <div id="home-residency-main">
      <div id="home-residency-main-tapes-front" class="home-residency-main-tapes-wrapper">
        <span class="home-residency-main-tape" data-repeat="Upcoming Resident"></span>
        <span class="home-residency-main-tape" data-repeat="${this.name}"></span>
      </div>
      <a target="_blank" href="${this.url}" id="home-residency-main-link">
        <figure id="home-residency-main-image">
          <img src="${this.image}" alt="residency featured artist">
        </figure>
      </a>
      <div id="home-residency-main-tapes-back" class="home-residency-main-tapes-wrapper">
        <span class="home-residency-main-tape" data-repeat="Upcoming Resident"></span>
        <span class="home-residency-main-tape" data-repeat="${this.name}"></span>
      </div>
    </div>
    `;
  }
}
