import { LitElement, html } from 'lit-element';

export default class ExhibitionContainer extends LitElement {
  static get properties() {
    return {
      exhibitions: { type: Array, },
    };
  }

  createRenderRoot() {
    return this;
  }

  updated() {
    if ( this.exhibitions.length ) {
      this.exhibitions.forEach((exhibition, i) => {
        let node = document.createElement('aom-exhibition-single');
        node.url = exhibition.url;
        node.title = exhibition.title;
        node.image = exhibition.image;
        node.image_sm = exhibition.image_sm;
        node.image_lg = exhibition.image_lg;
        this.querySelector('.exhibitions-wrapper').appendChild(node);
      });
    }
  }

  render() {
    return html`
    <div class="exhibitions">
      <div class="exhibitions-wrapper">
        <!-- Populated by JS -->
      </div>
    </div>
    `;
  }
}
