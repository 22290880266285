import { LitElement, html } from 'lit-element';
import CatalogueURL from '/components/catalogue/CatalogueURL.js';

export default class FeaturedArtist extends LitElement {
  static get properties() {
    return {
      artists: { type: Array, },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.artists = [];
  }

  updated() {
    if ( this.artists.length ) {
      this.artists.forEach((artist, i) => {
        let node = document.createElement('aom-featured-artist');
        node.url = CatalogueURL.getURL(artist);
        node.name = artist.name;
        node.excerpt = artist.excerpt;
        node.mugshot = artist.mugshot;
        node.background = artist.featured_background;
        this.querySelector('div').appendChild(node);
      });
    }
  }

  render() {
    return html`
      <div>
        <!-- Populated by JS -->
      </div>
    `;
  }
}
