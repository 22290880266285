import Topbar from '/components/Topbar.js';
import Footer from '/components/Footer.js';
import Main from '/components/Main.js';
import Home from '/components/Home.js';
import SectionHeading from '/components/SectionHeading.js';
import CatalogueContainer from '/components/catalogue/CatalogueContainer.js';
import CatalogueArtist from '/components/catalogue/CatalogueArtist.js';
import CataloguePageContainer from '/components/catalogue/CataloguePageContainer.js';
import Catalogue from '/components/catalogue/Catalogue.js';
import CatalogueSingle from '/components/catalogue/CatalogueSingle.js';
import FeaturedContainer from '/components/home/FeaturedContainer.js';
import FeaturedArtist from '/components/home/FeaturedArtist.js';
import ResidencyUpcoming from '/components/residency/ResidencyUpcoming.js';
import ResidencyInstallationContainer from '/components/residency/ResidencyInstallationContainer.js';
import ResidencyInstallation from '/components/residency/ResidencyInstallation.js';
import Residency from '/components/residency/Residency.js';
import ExhibitionContainer from '/components/exhibition/ExhibitionContainer.js';
import ExhibitionSingle from '/components/exhibition/ExhibitionSingle.js';
import Exhibition from '/components/exhibition/Exhibition.js';
import About from '/components/about/About.js';

customElements.define('aom-topbar', Topbar);
customElements.define('aom-footer', Footer);
customElements.define('aom-main', Main);
customElements.define('aom-home', Home);
customElements.define('aom-section-heading', SectionHeading);
customElements.define('aom-catalogue-container', CatalogueContainer);
customElements.define('aom-catalogue-artist', CatalogueArtist);
customElements.define('aom-catalogue-page-container', CataloguePageContainer);
customElements.define('aom-catalogue', Catalogue);
customElements.define('aom-catalogue-single', CatalogueSingle);
customElements.define('aom-featured-container', FeaturedContainer);
customElements.define('aom-featured-artist', FeaturedArtist);
customElements.define('aom-residency-upcoming', ResidencyUpcoming);
customElements.define('aom-residency-installation-container', ResidencyInstallationContainer);
customElements.define('aom-residency-installation', ResidencyInstallation);
customElements.define('aom-residency', Residency);
customElements.define('aom-exhibition-container', ExhibitionContainer);
customElements.define('aom-exhibition-single', ExhibitionSingle);
customElements.define('aom-exhibition', Exhibition);
customElements.define('aom-about', About);
