var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
  else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css, property, customElement } from 'lit-element';
;
let ImVideo = class ImVideo extends LitElement {
  constructor() {
      super(...arguments);
      this.src = '';
      this.srcset = '';
      this.media = [];
      this.controls = false;
      this.autoplay = false;
      this.muted = false;
      this.playsinline = false;
      this.loop = false;
  }
  static get styles() {
      return [
          css `
      :host {
        width: 100%;
        height: 100%;
        display: block;
      }

      video {
        width: 100%;
        height: 100%;
      }
    `,
      ];
  }
  firstUpdated() {
      !this.src && console.error(`<im-video> Error: 'src' is empty.`);
      /**
       *  Creates object to store media and source then attach listener.
       */
      if (this.srcset) {
          let replaced = this.srcset.replace(/(\r\n|\n|\r)/gm, '');
          let splits = replaced.split(', ');
          let sets = splits.map(str => str.trim());
          this.media = sets.map(set => {
              return {
                  src: set.split(' ')[0],
                  size: parseInt(set.split(' ')[1]),
              };
          });
          this.media = this.media.sort((a, b) => a.size < b.size ? 1 : -1);
          // NOTE: Push in widest and smallest size.
          this.media.unshift({ src: this.src, size: 9999 });
          window.addEventListener('resize', this.handleResize.bind(this));
          this.updateSourceByScreenWidth(); // NOTE: Runs on load.
      }
  }
  /**
   *  Update `src` from `this.media` property by screen width.
   */
  updateSourceByScreenWidth() {
      if (this.media.length) {
          this.media.forEach((medium, i) => {
              var _a, _b;
              let nextSize = (_b = (_a = this.media[i + 1]) === null || _a === void 0 ? void 0 : _a.size) !== null && _b !== void 0 ? _b : 0;
              if (screen.width < medium.size && screen.width > nextSize) {
                  this.src = medium.src;
              }
          });
      }
  }
  /**
   *  Handles 'resize' event.
   */
  handleResize(ev) {
      this.updateSourceByScreenWidth();
  }
  disconnectedCallback() {
      if (this.media.length) {
          window.removeEventListener('resize', this.handleResize.bind(this));
      }
  }
  render() {
      return html `
    <video
      src="${this.src}"
      ?controls=${this.controls}
      ?muted=${this.muted}
      ?autoplay=${this.autoplay}
      ?loop=${this.loop}
      ?playsinline=${this.playsinline}
      >
      <slot></slot>
    </video>
  `;
  }
};
__decorate([
  property({ type: String, reflect: true })
], ImVideo.prototype, "src", void 0);
__decorate([
  property({ type: String, reflect: true })
], ImVideo.prototype, "srcset", void 0);
__decorate([
  property({ type: Array })
], ImVideo.prototype, "media", void 0);
__decorate([
  property({ type: Boolean, reflect: true })
], ImVideo.prototype, "controls", void 0);
__decorate([
  property({ type: Boolean, reflect: true })
], ImVideo.prototype, "autoplay", void 0);
__decorate([
  property({ type: Boolean, reflect: true })
], ImVideo.prototype, "muted", void 0);
__decorate([
  property({ type: Boolean, reflect: true })
], ImVideo.prototype, "playsinline", void 0);
__decorate([
  property({ type: Boolean, reflect: true })
], ImVideo.prototype, "loop", void 0);
ImVideo = __decorate([
  customElement('im-video')
], ImVideo);
