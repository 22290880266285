/**
 *  An object that processes an artist datum and spits out the url to catalogue
 */

const CatalogueURL = {
  getURL(artist) {
    let { year, name } = artist;
    let urlName = encodeURIComponent(name.toLowerCase().replaceAll(' ', '_'));
    return `catalogue/${year}-${urlName}`;
  },
  getArtist(url, artists) {
    let artistURL = url.split('/').pop();
    let year = artistURL.split('-').shift();
    let name = artistURL.split('-').pop();
    let urlName = decodeURIComponent(name.toLowerCase());
    
    let artist = artists.find(artist => {
      let artistName = decodeURIComponent(artist.name.toLowerCase().replaceAll(' ', '_'));
      if ( artistName == urlName ) {
        return artist;
      }
    });

    return artist ? artist : false;
  }
}

export default CatalogueURL;
