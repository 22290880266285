import { LitElement, html } from 'lit-element';

export default class Catalogue extends LitElement {
  static get properties() {
    return {
      artists: { type: Array },
    };
  }

  createRenderRoot() {
    return this;
  }

  async firstUpdated() {
    if ( this.artists.length ) {
      let artistsAllYears = this.artists.map(artist => artist.year);
      let years = [...new Set(artistsAllYears)];

      // NOTE: Get artists by year.
      await years.forEach(async (year) => {
        let artists = this.artists.filter(artist => artist.year == year);
        let node = document.createElement('aom-catalogue-page-container');
        node.year = year;
        node.artists = artists;
        this.appendChild(node);
      });
    }
  }
}
