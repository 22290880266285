import { LitElement, html } from 'lit-element';
import CatalogueURL from '/components/catalogue/CatalogueURL.js';

export default class CatalogueContainer extends LitElement {
  static get properties() {
    return {
      artists: { type: Array, },
    };
  }

  createRenderRoot() {
    return this;
  }

  updated() {
    if ( this.artists.length ) {
      this.artists.forEach((artist, i) => {
        let node = document.createElement('aom-catalogue-artist');
        node.url = CatalogueURL.getURL(artist) ;
        node.name = artist.name;
        node.designation = artist.designation;
        node.mugshot = artist.mugshot;
        // node.altText = artist.altText;
        this.querySelector('.catalogue-container').appendChild(node);
      });
    }
  }

  render() {
    return html`
    <div class="catalogue">
      <div class="catalogue-container">
        <!-- Populated by JS -->
      </div>
    </div>
    `;
  }
}
